.column {
    margin-left: -20px;
    margin-top: -20px;
    height: 100%;
    display: table-cell;
    float: none;
    height: 100%;
    padding: 0;
    vertical-align: top;
}

.col-100p {
    width: 100%;
}

.col-200 {
    width:200px;
}

.col-250 {
    width:250px;
}

.col-300 {
    width:300px;
}

.col-350 {
    width:350px;
}

.col-375 {
    width:375px;
}

.col-600 {
    width:600px;
}

.section-header {
    background-color: #fbfbfb;
    border-bottom: 1px solid #dee5e7;
    color: #aaa;
    padding: 20px;
}

.table-display {
    border-spacing: 0;
    display: table;
    height: 100%;
    position: relative;
    width: 100%;
}

.table-row {
    display: table-row;
    height: 100%;
}

.table-cell {
    display: table-cell;
    height: 100%;
    overflow-y: hidden;
    position: relative;
    width: 100%;
}

.a-box {
    bottom: 0;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    z-index: 1;
}

.layout-fixed {
    border-spacing: 0;
    display: table;
    height: 100%;
    margin: 0;
    table-layout: fixed;
    width: 100%;
}

.box-border-r {
    border-right: 1px solid #d5d5d5 !important;
}

.bg-white {
    background-color: #fff !important;
}

.wrapper {
    height:100% !important;
    position:absolute;
}

.bordered-segment {
    border-bottom: 1px solid #cfdbe2;
    margin: -20px -20px 20px;
    margin-bottom:0px;
    padding: 20px;
}

.column-heading {
    padding-left: 10px;
}

.pointer {
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.column-selected {
    border-left: 5px solid red;
    font-weight: bold;
}

.modal-header {
    margin-top: 0px;
}

.modal-small {
    width: 400px;
}

input.has-error {
    color: red;
    font-weight: bold;
}

input.has-success {
    color: green;
    font-weight: bold;
}

.panel-footer-ready {
    background-color: rgba(246, 255, 37, 0.2);
}

.panel-footer-active {
    background-color: rgba(0, 255, 0, 0.2);
}

.panel-footer-waiting {
    background-color: rgba(254, 255, 11, 0.20);
}

tr.highlight {
    background-color: rgba(255, 235, 43, 0.50);
}

tr.markedDNA {
    background-color: rgba(255, 0, 0, .3);
}

tr.markedPASS {
    background-color: rgba(0, 255, 0, .3);
}

.selected-prelim {
    border: 2px solid gray;
    padding: 2px;
    border-radius: 6px;
    opacity: 1;
}

.animate-hide-button {
    transition: all linear 0.5s;
    width: 70px;
    opacity: 1;
}
.animate-hide-button.ng-hide {
    width: 0px;
    opacity: 0;
}  
.has-prelims {
    background-color: #eee !important; 
}  
.no-prelims {
    background-color: #fafafa !important;
}
.active-round {
    border-top-color: #FFCC66;
}
.completed-round {
    border-top-color: #008040;
}
.prelims-round {
    border-top-color: #FF6666;
}

.w-30 {
    width: 30px !important;
}

.w-50 {
    width: 50px !important;
}

.w-100 {
    width: 100px !important;
}

.w-150 {
    width: 150px !important;
}

.h-24 {
    height: 24px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}


.cg-notify-message-center {
    left: 0px !important;
    margin-left: 0px !important;
    width:100% !important;
    max-width:5000px !important;
    margin-top: -57px !important;
    top: 58px !important;
}

.cg-notify-message {
    background-color: #5d9cec !important;
    color: white !important;
}



