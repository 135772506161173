/*!
 *
 * Angle - Bootstrap Admin App + AngularJS
 *
 * Version: 3.4
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

 [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

// Override bootstrap variables
@import "app/variables";

// Bootstrap
@import "bootstrap/bootstrap/_mixins";

// Global definition of media queries
@import "app/media-queries";
// Utilities
@import "app/utils-definitions";

// Bootstrap reset
@import "app/bootstrap-reset";

// Typography
@import "app/typo";

// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";

/*@import "app/layouts/other";
@import "app/layouts/mixins";
@import "app/layouts/aside";
@import "app/layouts/aside.docked";
@import "app/layouts/aside.fixed";
@import "app/layouts/aside.folded";*/


@import "app/form-wizard";
@import "app/form-elements";
@import "app/form-validation";
@import "app/plugins";
@import "app/spinner";

// Components
@import "app/preloader";
@import "app/breadcrumbs";
@import "app/loading-bar";
@import "app/animate";
@import "app/datepicker";
@import "app/button-extra";

// Utilities
@import "app/utils";

// Print CSS
@import "app/print";

// my additions
@import "app/custom";
